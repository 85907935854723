import React from 'react'
import { useNavigate } from "react-router-dom";

const Appointmentcard = () => {
    const navigate = useNavigate();
  return (
    <>
            <div className="card card-1">
              <div
                className="card--data"
                onClick={() => navigate("/doctorappointmentlist")}
              >
                <div className="card--content">
                  <h5 className="card--title">Appointment</h5>
                  <h1>152</h1>
                </div>
                <i className="ri-calendar-2-line"></i>
              </div>
              <div className="card--stats">
                <span>
                  <i className="ri-bar-chart-fill card--icon stat--icon"></i>65%
                </span>
                <span>
                  <i className="ri-arrow-up-s-fill card--icon up--arrow"></i>10
                </span>
                <span>
                  <i className="ri-arrow-down-s-fill card--icon down--arrow"></i>
                  2
                </span>
              </div>
            </div>
    </>
  )
}

export default Appointmentcard
