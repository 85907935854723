import React from 'react'
import { useNavigate } from "react-router-dom";

const Patientcard = () => {
    const navigate = useNavigate();
  return (
    <>
        <div className="card card-2">
              <div
                className="card--data"
                onClick={() => navigate("/doctorpatientlist")}
              >
                <div className="card--content">
                  <h5 className="card--title">Total Patients</h5>
                  <h1>1145</h1>
                </div>
                <i className="ri-user-line card--icon--lg"></i>
              </div>
              <div className="card--stats">
                <span>
                  <i className="ri-bar-chart-fill card--icon stat--icon"></i>82%
                </span>
                <span>
                  <i className="ri-arrow-up-s-fill card--icon up--arrow"></i>230
                </span>
                <span>
                  <i className="ri-arrow-down-s-fill card--icon down--arrow"></i>
                  45
                </span>
              </div>
            </div>
    </>
  )
}

export default Patientcard;
