import React from "react";
import Drdashboard from "../component/drdashboard";
import Drdashheader from "../component/drdashheader";



const Doctorspage = () => {
  return (
    <>

      {<Drdashheader/>}
      {<Drdashboard />}
      


    </>
  );
};

export default Doctorspage;
