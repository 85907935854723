import React from 'react'

const Paymentcard = () => {
  return (
    <>
      
      <div className="card card-3">
              <div className="card--data">
                <div className="card--content">
                  <h5 className="card--title">Payments</h5>
                  <h1>1500</h1>
                </div>
                <i class="ri-money-rupee-circle-line card--icon--lg"></i>
              </div>
              <div className="card--stats">
                <span>
                  <i className="ri-bar-chart-fill card--icon stat--icon"></i>27%
                </span>
                <span>
                  <i className="ri-arrow-up-s-fill card--icon up--arrow"></i>31
                </span>
                <span>
                  <i className="ri-arrow-down-s-fill card--icon down--arrow"></i>
                  23
                </span>
              </div>
            </div>
    </>
  )
}

export default Paymentcard
