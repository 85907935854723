import React from 'react'
import Header from '../component/header';
import Contactus from '../component/contactus';

const Contactuspage = () => {
  return (
    <>
    {<Header/>}
    {<Contactus/>}
      
    </>
  )
}

export default Contactuspage;
