import React from 'react'
import Header from '../component/header'
import Drregistration from '../component/drregistration'

const Drregistrationpage = () => {
  return (
    <>
    <Header/>
    <Drregistration/>
      
    </>
  )
}

export default Drregistrationpage;
