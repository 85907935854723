import React from 'react'
import Header from '../component/header';
import Dashboard from '../component/dashboard';


const Dashboardpage = () => {
  return (
    <>
    {<Header/>}
    {<Dashboard/>}
   
      
    </>
  )
}

export default Dashboardpage;
