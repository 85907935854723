import React, { useState } from 'react';
import "../css/header.css";
import logomed from './image/logomed.png';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img className="logo" src={logomed} alt="" />
        <div className='name'><b>Medical</b> Health care</div>
      </div>
      <div className={`nav-links ${isOpen ? 'active' : ''}`}>
        <li><a onClick={() => navigate('/')}>Home</a></li>
        {/* <li><a onClick={() => navigate('/treatments')}>Treatments</a></li> */}
        <li><a onClick={() => navigate('/doctors')}>Doctors</a></li>
        <li><a onClick={() => navigate('/service')}>Service</a></li>
        <li><a onClick={() => navigate('/aboutus')}>About Us</a></li>
        <li><a onClick={() => navigate('/contactus')}>Contact Us</a></li>  
        <li><a onClick={() => navigate('/dashboard')}>Login</a></li>
      </div>
      <div className={`hamburger ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Header;