import React from 'react'
import Header from '../component/header';
import Aboutus from '../component/aboutus';


const Aboutuspage = () => {
  return (
    <>
    {<Header/>}
    {<Aboutus/>}
      
    </>
  )
}

export default Aboutuspage;
