import React from 'react'
import Drdashheader from '../component/drdashheader';
import Doctorpatientlist from '../component/doctorpatientlist';

const Doctorpatientlistpage = () => {
  return (
    <>
    {<Drdashheader/>}
    {<Doctorpatientlist/>}
      
    </>
  )
}

export default Doctorpatientlistpage;
