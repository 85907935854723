import React from 'react'

const Patientreview = () => {
  return (
    <>
       <div className="patient-review-container">
              <div className="review-type">
                <h3>Patients Review</h3>
              </div>
              <div className="review-items">
                <div className="review-item">
                  <div className="review-label">Excellent</div>
                  <div className="review-bar">
                    <div
                      className="review-bar-fill"
                      style={{ width: "90%", background: "#007bff" }}
                    ></div>
                  </div>
                </div>
                <div className="review-item">
                  <div className="review-label">Great</div>
                  <div className="review-bar">
                    <div
                      className="review-bar-fill"
                      style={{ width: "80%", background: "#28a745" }}
                    ></div>
                  </div>
                </div>
                <div className="review-item">
                  <div className="review-label">Good</div>
                  <div className="review-bar">
                    <div
                      className="review-bar-fill"
                      style={{ width: "70%", background: "#ffc107" }}
                    ></div>
                  </div>
                </div>
                <div className="review-item">
                  <div className="review-label">Average</div>
                  <div className="review-bar">
                    <div
                      className="review-bar-fill"
                      style={{ width: "40%", background: "#53dada " }}
                    ></div>
                  </div>
                </div>
                <div className="review-item">
                  <div className="review-label">Bad </div>
                  <div className="review-bar">
                    <div
                      className="review-bar-fill"
                      style={{ width: "20%", background: "#f46464" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
    </>
  )
}

export default Patientreview
