import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/doctorappointmentlist.css'

const Doctorappointmentlist = () => {
  const [userlist, setuserlist] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    userlistData()
  }, []);
  
  const userlistData = () => {
    return axios.get('http://localhost:8080/appointment/appointmentlist/')
      .then((response) => {
        setuserlist(response.data.message)
      })
      .catch((error) => {
        console.error("Error fetching appointment list:", error);
      });
  }
  
  const viewHandler = (id) => {
    axios.get('http://localhost:8080/appointment/appointmentlist/'+id)
      .then((response) => {
        setSelectedAppointment(response.data);
        setIsModalOpen(true);
      })
      .catch((error) => {
        console.error("Error fetching appointment details:", error);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAppointment(null);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
   // Scroll back to top
   const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className="doctor-patient-list">
      <div className="table-container">
        <table className='patient-details'>
          <thead>
            <tr>
              <th>Id</th>
              <th>Full Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Mobile</th>
              <th>Date</th>
              <th>Address</th>
              <th>Show Details</th>
            </tr>
          </thead>
          <tbody>
            {userlist && userlist.map((user) => (
              <tr key={user.id}>
                <td data-label="Id">{user.id}</td>
                <td data-label="Full Name">{user.fullname}</td>
                <td data-label="Age">{user.age}</td>
                <td data-label="Gender">{user.gender}</td>
                <td data-label="Mobile">{user.mobile}</td>
                <td data-label="Date">{user.date}</td>
                <td data-label="Address">{user.address}</td>
                <td><button className='viewdetails' onClick={() => viewHandler(user.id)}>View</button></td>
              </tr>
            ))}
          </tbody>
        </table>

        {showTopBtn && (
        <div className="top-to-btm" onClick={scrollToTop}>
          <i class="ri-arrow-up-s-line"></i>
          <span>Top</span>
        </div>
      )}
      </div>

      {isModalOpen && selectedAppointment && (
        <div className="modal-overlay" >
          <div className="modal-content">
            <h2>Appointment Details</h2>
            <p><strong>ID:</strong> {selectedAppointment.id}</p>
            <p><strong>Full Name:</strong> {selectedAppointment.fullname}</p>
            <p><strong>Age:</strong> {selectedAppointment.age}</p>
            <p><strong>Gender:</strong> {selectedAppointment.gender}</p>
            <p><strong>Mobile:</strong> {selectedAppointment.mobile}</p>
            <p><strong>Date:</strong> {selectedAppointment.date}</p>
            <p><strong>Address:</strong> {selectedAppointment.address}</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Doctorappointmentlist;