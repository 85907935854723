import { useNavigate } from "react-router-dom";
import "../css/drregistration.css";
import drregistrationimg from "./image/drregistrationimg.png";
import { useState } from "react";
import axios from 'axios';


const Drregistration = () => {
  const navigate = useNavigate();

  const [doctor_name, doctornameSet] = useState('');
  const [doctor_email, doctoremailSet] = useState('');
  const [doctor_mobile, doctormobileSet] = useState('');
  const [speciality, specialitySet] = useState('');
  const [password, passwordSet] = useState('');

  const doctornameHandler =(event)=>{
    doctornameSet(event.target.value)
  }
  const doctoremailHandler =(event)=>{
    doctoremailSet(event.target.value)
  } 
  const doctormobileHandler =(event)=>{
    doctormobileSet(event.target.value)
  } 
  const specialityHandler =(event)=>{
    specialitySet(event.target.value)
  } 
  const passwordHandler =(event)=>{
    passwordSet(event.target.value)
  }

  const submitHandler =()=>{
 
   
    let drregistrationData ={doctor_name,doctor_email,doctor_mobile,speciality,password};
    return axios.post('http://localhost:8080/doctor/doctorregistration/',drregistrationData).then((response)=>{
      console.log(response);
     
    
    })
    
  }

  return (
    <>

<form   name='drregistrationform' onSubmit={submitHandler}>
   
    <div className="drregistrationbox">
          <div class="registrationformbox">
            <h3>Registration to Doctor</h3>
            <div class="input-field1">

              <input type="text" placeholder="Doctor name"  name='Doctor name' onChange={doctornameHandler}
              id='name' data-required="true" data-error-message="Your Name Is Required"/>
            </div>
            <div class="input-field1">
        
              <input type="text" placeholder="Doctor email"  name='Doctor email' onChange={doctoremailHandler}
              id='Doctor email' data-required="true" data-error-message="Your Email Is Required"/>
            </div>
            <div class="input-field1">
        
              <input type="text" placeholder="Doctor mobile" name='Doctor mobile' onChange={doctormobileHandler}
              id='Doctor mobile' data-required="true" data-error-message="Your Mobile Is Required"/>
            </div>
            <div class="input-field1">
        
              <input type="text" placeholder="Speciality"  name='Speciality' onChange={specialityHandler}
              id='Speciality' data-required="true" data-error-message="Your Speciality Is Required" />
            </div>
            <div class="input-field1">
        
              <input type="password" placeholder="Password"  name='password' onChange={passwordHandler}
              id='password' data-required="true" data-error-message="Your Password Is Required" />
            </div>
          
            <main class="policy">
        <input type="checkbox"/>
        <div>I accept all terms & condition</div>
            </main>

            <section>
              <button className="drregisterbtn">Register Now</button>
            </section>
           
            <div >
             <div class="dralready">Already have an account ? <a className="dralready1" onClick={() => navigate('/dashboard')}> Login</a></div>
            </div>
            
          
          </div>
        </div>

  
    
    
    <figure className='drregistrationimg'>
    <img src={drregistrationimg} alt="" />
   
    </figure>
  </form>
     


     
    </>
  );
};

export default Drregistration;


