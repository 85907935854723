
import React, { useState, useEffect } from 'react';

const Patientcalendar = () => {
    const [date, setDate] = useState(new Date());
  const [currYear, setCurrYear] = useState(date.getFullYear());
  const [currMonth, setCurrMonth] = useState(date.getMonth());



  const months = ["January", "February", "March", "April", "May", "June", "July",
                  "August", "September", "October", "November", "December"];

  const renderCalendar = () => {
    let firstDayofMonth = new Date(currYear, currMonth, 1).getDay();
    let lastDateofMonth = new Date(currYear, currMonth + 1, 0).getDate();
    let lastDayofMonth = new Date(currYear, currMonth, lastDateofMonth).getDay();
    let lastDateofLastMonth = new Date(currYear, currMonth, 0).getDate();

    let daysArray = [];

    for (let i = firstDayofMonth; i > 0; i--) {
      daysArray.push({ day: lastDateofLastMonth - i + 1, className: "inactive" });
    }

    for (let i = 1; i <= lastDateofMonth; i++) {
      let isToday = i === date.getDate() && currMonth === new Date().getMonth() 
                    && currYear === new Date().getFullYear() ? "active" : "";
      daysArray.push({ day: i, className: isToday });
    }

    for (let i = lastDayofMonth; i < 6; i++) {
      daysArray.push({ day: i - lastDayofMonth + 1, className: "inactive" });
    }

    return daysArray;
  };

  const handleIconClick = (direction) => {
    let newMonth = direction === "prev" ? currMonth - 1 : currMonth + 1;
    let newYear = currYear;

    if (newMonth < 0 || newMonth > 11) {
      newYear = direction === "prev" ? currYear - 1 : currYear + 1;
      newMonth = direction === "prev" ? 11 : 0;
    }

    setCurrMonth(newMonth);
    setCurrYear(newYear);
    setDate(new Date(newYear, newMonth, new Date().getDate()));
  };
  return (
    <>
         <div className="calendarContainer">
             <header className="headerSection">
               <p className="dateDisplay">{`${months[currMonth]} ${currYear}`}</p>
               <div className="navigationIcons">
                 <span id="prev" className="iconWrapper" onClick={() => handleIconClick("prev")}>
                   <i class="ri-arrow-left-s-line"></i>
                 </span>
                 <span id="next" className="iconWrapper" onClick={() => handleIconClick("next")}>
                   <i class="ri-arrow-right-s-line"></i>
                 </span>
               </div>
             </header>
             <div className="calendarBody">
               <ul className="weekDays">
                 <li>Sun</li>
                 <li>Mon</li>
                 <li>Tue</li>
                 <li>Wed</li>
                 <li>Thu</li>
                 <li>Fri</li>
                 <li>Sat</li>
               </ul>
               <ul className="dateGrid">
                 {renderCalendar().map((day, index) => (
                   <li key={index} className={day.className}>{day.day}</li>
                 ))}
               </ul>
             </div>
        </div> 
    </>
  )
}

export default Patientcalendar
