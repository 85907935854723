import React from 'react'
import '../css/aboutus.css'
import aboutuspic1 from "./image/aboutuspic1.png"
import doctoraboutpic from "./image/doctoraboutpic.png"

const Aboutus = () => {
  return (
    <>
      <section className='aboutus-section'>
        <figure className="aboutus-pic-container">
          <img className='aboutus-pic' src={aboutuspic1} alt="" />
        </figure>
        <div className="aboutus-content">
          <div className="aboutheading">WHO WE ARE</div>
          <div className='aboutheading-paragraph'>
            <p>At medical health care, we are driven by the zeal to put</p>
            <p>'kindness' & 'care' back into modern medical services.</p>
            <p>our mission is to make medical care easily available to</p>
            <p>the people in the society and make the cost pocket friendly.</p>
          </div>
        </div>
      </section>

      <section className='background-gray'>
        <div className="about-content">
          <img className='about-icon' src="https://www.kynohealth.com/static/media/d_qts_icon2.8ad9ce3a96481b033e27.svg" alt="" />
          <p className='about-heading'>Our team consists of qualified, trained and sensitive doctors and medical staff. 
          Our team consists of qualified, trained and sensitive doctors and medical staff. 
          We believe that apart from the best quality of medicines and healthcare equipment, 
          it is equally important to train our teams to be humane and compassionate. Our aim 
          is to provide good service to the patients and make people aware of the disease 
          and we provide 24X7 service for any emergency.</p>
          <img className='about-icon2' src="https://www.kynohealth.com/static/media/d_qts_icon2.8ad9ce3a96481b033e27.svg" alt="" />
        </div>
        <figure className="doctoraboutpic-container">
          <img className='doctoraboutpic' src={doctoraboutpic} alt="" />
        </figure>
      </section>

      <section className="aboutus-cards">
        <div className="aboutus-card">
          <p className='aboutsus-card-text'>Our Vision</p>
          <p className='aboutsus-card-text-para'>To position oursleves in the lead role on the healthcare map</p>
        </div>

        <div className="aboutus-card">
          <p className='aboutsus-card-text'>Our Mission</p>
          <p className='aboutsus-card-text-para'>To achieve excellence brin health care with evidence based ethical clinical practices by a team of highly skilled professionals using cutting edge technology.</p>
        </div>
        
        <div className="aboutus-card">
          <p className='aboutsus-card-text'>Core Values</p>
          <p className='aboutsus-card-text-para'>To carry the highest standards of integrity, respect, inclusiveness, transparency and adherence to ethical standards</p>
        </div>
      </section>
    </>
  )
}

export default Aboutus;