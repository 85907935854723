import React, { useState } from 'react'
import '../css/bookanappointmentform.css'
import axios from 'axios'

const Bookanappointmentform = () => {
  const [fullname, fullnameSet] = useState('')
  const [age, ageSet] = useState('')
  const [gender, genderSet] = useState('')
  const [mobile, mobileSet] = useState('')
  const [date, dateSet] = useState('')
  const [address, addressSet] = useState('')
  const [errors, setErrors] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)

  const fullnameHandler = (event) => {
    fullnameSet(event.target.value)
  }
  const ageHandler = (event) => {
    ageSet(event.target.value)
  }
  const genderHandler = (event) => {
    genderSet(event.target.value)
  }
  const mobileHandler = (event) => {
    mobileSet(event.target.value)
  }
  const dateHandler = (event) => {
    dateSet(event.target.value)
  }
  const addressHandler = (event) => {
    addressSet(event.target.value)
  }

  const validateForm = () => {
    let tempErrors = {}
    if (!fullname) tempErrors.fullname = "Full name is required"
    if (!age) tempErrors.age = "Age is required"
    else if (isNaN(age) || age < 0 || age > 110) tempErrors.age = "Please enter a valid age"
    if (!gender) tempErrors.gender = "Gender is required"
    if (!mobile) tempErrors.mobile = "Mobile number is required"
    else if (!/^\d{10}$/.test(mobile)) tempErrors.mobile = "Please enter a valid 10-digit mobile number"
    if (!date) tempErrors.date = "Date is required"
    if (!address) tempErrors.address = "Address is required"
    setErrors(tempErrors)
    return Object.keys(tempErrors).length === 0
  }

  const submitHandler = (event) => {
    event.preventDefault()
    if (validateForm()) {
      let appointmentData = { fullname, age, gender, mobile, date, address }
      axios.post('http://localhost:8080/appointment/appointmentregistration/', appointmentData)
        .then((response) => {
          console.log(response)
          setIsSubmitted(true)
        })
        .catch((error) => {
          console.error('Error submitting form:', error)
        })
    }
  }

  if (isSubmitted) {
    return (
      <div className="success-message">
        <div className="checkmark">✓</div>
        <h2>Thank you!</h2>
        <p>Your request for appointment has been successfully submitted.</p>
        <p>Our executive will call you soon to assist you with this request.</p>
        <p className="note">
          Note: This is not a confirmation of your appointment and will not
          be considered as an appointment if you visit the OPD directly on
          the requested date.
        </p>
      </div>
    )
  }

  return (
    <div className="appointment-container">
      <div className="appointment-wrapper">
        <form onSubmit={submitHandler}>
          <div className="fillpatient">Please fill appointment details below:</div>

          <div className="appointment-form-group">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Enter Your Full Name"
              onChange={fullnameHandler}
              className="appointment-input"
            />
            {errors.fullname && <div className="error">{errors.fullname}</div>}
          </div>

          <div className="appointment-form-group">
            <input
              type="number"
              name="age"
              id="age"
              placeholder="Enter your Age"
              onChange={ageHandler}
              className="appointment-input"
            />
            {errors.age && <div className="error">{errors.age}</div>}
          </div>

          <div className="appointment-form-group">
            <select
              name='gender'
              onChange={genderHandler}
              id='gender'
              className="appointment-input-gender"
            >
              <option value="">Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            {errors.gender && <div className="error">{errors.gender}</div>}
          </div>

          <div className="appointment-form-group">
            <input
              type="text"
              name="phone"
              id="phone"
              placeholder="Enter your Mobile number"
              onChange={mobileHandler}
              className="appointment-input"
            />
            {errors.mobile && <div className="error">{errors.mobile}</div>}
          </div>

          <div className="appointment-form-group">
            <input
              type="date"
              name="date"
              id="date"
              className="appointment-input"
              onChange={dateHandler}
            />
            {errors.date && <div className="error">{errors.date}</div>}
          </div>

          <div className="appointment-form-group">
            <input
              type="text"
              name="area"
              id="area"
              placeholder="Enter Your Address"
              onChange={addressHandler}
              className="appointment-input"
            />
            {errors.address && <div className="error">{errors.address}</div>}
          </div>

          <div className="form-group">
            <button className="appointment-button">Request for Appointment</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Bookanappointmentform