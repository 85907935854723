
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { Phone, ChevronLeft, ChevronRight } from 'lucide-react';




const Analytics = () => {


    const heartRateData = [
        { day: 'Sat', rate: 135 },
        { day: 'Sun', rate: 155 },
        { day: 'Mon', rate: 125 },
        { day: 'Tue', rate: 140 },
        { day: 'Wed', rate: 167 },
        { day: 'Thu', rate: 135 },
        { day: 'Fri', rate: 120 },
      ];
  
      
  return (
    <>
    
<div className="patient-card">
<div className="card-header">
  <div className="analytic">Analytics</div>

</div>
<div className="tab-group">
  <button className="tab-btn ">Heart Rate</button>
  <button className="tab-btn">Blood Pressure</button>
  <button className="tab-btn">Glucose</button>
 
</div>
<div className="chart-container">
  <ResponsiveContainer width="100%" height="100%">
    <LineChart data={heartRateData}>
      <XAxis dataKey="day" />
      <YAxis />
      <Line type="monotone" dataKey="rate" stroke="#6366f1" strokeWidth={2} />
    </LineChart>
  </ResponsiveContainer>
</div>
</div>
      
    </>
  )
}

export default Analytics;
