import React from 'react'
import Header from '../component/header';
import Bookanappointmentform from '../component/bookanappointmentform';


const Bookanappointmentformpage = () => {
  return (
    <>
    {<Header/>}
    {<Bookanappointmentform/>}
  
      
    </>
  )
}

export default Bookanappointmentformpage;
