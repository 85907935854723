
import "../css/prescriptionform.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';



const Patientregistration = () => {

const [name, nameSet] = useState('');
const [age, ageSet] = useState(''); 
const [mobile, mobileSet] = useState('');
const [gender, genderSet] = useState('');
const [symptoms, symptomsSet] = useState('');
const [treatment, treatmentSet] = useState('');
const [errors, setErrors] = useState({});
const [showSuccessMessage, setShowSuccessMessage] = useState(false);
const [submissionTimestamp, setSubmissionTimestamp] = useState(null);


const [doctor_id, getdoctor_id] = useState('');
const [doctor_mobile,getdoctor_mobile] = useState('');
const [doctor_name, getdoctor_name] = useState('');
const [doctor_email,getdoctor_email] = useState('');

const nameHandler =(event)=>{
  nameSet(event.target.value)
}
const ageHandler =(event)=>{
  ageSet(event.target.value)
}
const genderHandler =(event)=>{
  genderSet(event.target.value)
}
const mobileHandler =(event)=>{
  mobileSet(event.target.value)
}
const symptomsHandler =(event)=>{
  symptomsSet(event.target.value)
}
const treatmentHandler =(event)=>{
  treatmentSet(event.target.value)
}





useEffect(()=>{
  getdoctor_id(localStorage.getItem('id'));
  getdoctor_mobile(localStorage.getItem('doctor_mobile'));
  getdoctor_name(localStorage.getItem('doctor_name'));
  getdoctor_email(localStorage.getItem('doctor_email'));

},[]);



const validateForm = () => {
  let tempErrors = {};
  if (!name.trim()) tempErrors.name = "Name is required";
  if (!age.trim()) tempErrors.age = "Age is required";
  else if (isNaN(age) || age < 0 || age > 120) tempErrors.age = "Please enter a valid age";
  if (!gender) tempErrors.gender = "Gender is required";
  if (!mobile.trim()) tempErrors.mobile = "Mobile number is required";
  else if (!/^\d{10}$/.test(mobile)) tempErrors.mobile = "Please enter a valid 10-digit mobile number";
  if (!symptoms.trim()) tempErrors.symptoms = "Symptoms are required";
  if (!treatment.trim()) tempErrors.treatment = "Treatment is required";
  setErrors(tempErrors);
  return Object.keys(tempErrors).length === 0;
};






const submitHandler =(event)=>{
  event.preventDefault();
    if (validateForm()) {
  

      const currentTimestamp = new Date().toISOString();
      setSubmissionTimestamp(currentTimestamp);

      let patientregistrationData = {
        name, age, gender, mobile, symptoms, treatment,password: '1234',submissionTimestamp: currentTimestamp,
        doctor_id, doctor_mobile, doctor_name, doctor_email,
      };
      axios.post('http://localhost:8080/auth/patientregistration/', patientregistrationData)
        .then((response) => {
          console.log(response);
          // Clear form or show success message here
          nameSet('')
          ageSet('')
          genderSet('')
          mobileSet('')
          symptomsSet('')
          treatmentSet('')

          setShowSuccessMessage(true);
        
          // Hide success message after 4 seconds
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 4000);

             // Show success message
            //  Swal.fire({
            //   icon: 'success',
            //   title: 'Success',
            //   text: 'Your Registration was submitted successfully!',
            //   confirmButtonText: 'OK',
            //   confirmButtonColor: '#0066ff'
            // });
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          // Handle error (you can show an error message here if needed)
        });
        // .catch((error) => {
        //   console.error("Error submitting form:", error);
        //   // Show error message to user here
        //     // Show error message
        //     Swal.fire({
        //       icon: 'error',
        //       title: 'Error',
        //       text: 'There was an error submitting your message. Please try again.',
        //       confirmButtonText: 'OK',
        //       confirmButtonColor: '#0066ff'
        //     });
        // });



      // Submission Time: {new Date(submissionTimestamp).toLocaleString()}   succes message with exact time !
  
    }
}

  return (
    <>

{showSuccessMessage && (
      <div className="success-message">
        Your Prescription Has Been Submit !
   
      </div>
    )}
 <form name='patientregistrationform' onSubmit={submitHandler}>
      <div className="patientregistrationbox">
        <div className="patientregistrationformbox">
          <h3>Prescription Form</h3>
          <div className="patient-input1">
            <input
              type="text"
              placeholder="Name"
              name='name'
              value={name}
              onChange={nameHandler}
              id='name'
            />
            {errors.name && <div className="error">{errors.name}</div>}
          </div>
          <div className="patient-input1">
            <input
              type="number"
              placeholder="Age"
              name='age'
              value={age}
              onChange={ageHandler}
              id='age'
            />
            {errors.age && <div className="error">{errors.age}</div>}
          </div>
          <div className="patient-input1">
            <select
              className="patient-gender"
              name='Gender'
              value={gender}
              onChange={genderHandler}
              id='Speciality'
            >
              <option value="" className="gender-color">Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            {errors.gender && <div className="error">{errors.gender}</div>}
          </div>
          <div className="patient-input1">
            <input
              type="text"
              placeholder="Mobile"
              name='mobile'
              value={mobile}
              onChange={mobileHandler}
              id='mobile'
            />
            {errors.mobile && <div className="error">{errors.mobile}</div>}
          </div>
          <div className="patient-input1">
            <textarea
              className="prescription-symptoms"
              placeholder="Symptoms"
              name='symptoms'
              value={symptoms}
              onChange={symptomsHandler}
              id='symptoms'
            />
            {errors.symptoms && <div className="error">{errors.symptoms}</div>}
          </div>
          <div className="patient-input1">
            <textarea
              className="prescription-treatment"
              placeholder="Treatment"
              name='treatment'
              value={treatment}
              onChange={treatmentHandler}
              id='treatment'
            />
            {errors.treatment && <div className="error">{errors.treatment}</div>}
          </div>
      
          <section>
            <button className="patientregisterbtn">Prescription</button>
          </section>
        </div>
      </div>
    </form>
     


     
    </>
  );
};

export default Patientregistration;


