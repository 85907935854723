import { useState } from "react";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../css/drdashboard.css";
import { FaFilePrescription } from "react-icons/fa";
import Appointmentcard from "./appointmentcard";
import Patientcard from "./patientcard";
import Prescriptioncard from "./Prescriptioncard";
import Paymentcard from "./paymentcard";
import Patientsummary from "./patientsummary";
import Patientreview from "./patientreview";

const Drdashboard = () => {
  const navigate = useNavigate();

  return (
    <>
  
      <section className="main--content">
        <div className="overview">
          <div className="cards">
      
      {/*card-1 */}
      {<Appointmentcard/>}


            {/*card-2 */}
      {<Patientcard/>}
       

            {/*card-4 */}
            {<Prescriptioncard/>}

 {/*card-3 */}
 {<Paymentcard/>}

       

          </div>
        </div>


 {/*patient summary */}
        <sectiony className="dashboard-main-content">
        <div className="row">
          <div className="col">
         

 {<Patientsummary/>}
          </div>

          <div className="col">
            {/*patientreview*/}
           {<Patientreview/>}
          </div>
        </div>
      </sectiony>

        
      </section>




   
    </>
  );
};

export default Drdashboard;























/* 

  
import { FaStar } from "react-icons/fa";

  const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9",
  };

  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0);
  const [name, setname] = useState("");

  const handleClick = (value) => {
    setCurrentValue(value);
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };
  const nameHandler = (event) => {
    setname(event.target.value);
  };

  const reviewhandle = () => {
    let reviewdata = {};
  };

       <form onSubmit={reviewhandle}>
          <div className="review">
            <h2> Share Your Reviews </h2>
            <div className="stars">
              {stars.map((_, index) => {
                return (
                  <FaStar
                    key={index}
                    size={24}
                    onClick={() => handleClick(index + 1)}
                    onMouseOver={() => handleMouseOver(index + 1)}
                    onMouseLeave={handleMouseLeave}
                    color={
                      (hoverValue || currentValue) > index
                        ? colors.orange
                        : colors.grey
                    }
                    style={{
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                  />
                );
              })}
            </div>
            <input className="namereview" type="text" placeholder="Name" name="name" onChange={nameHandler}
            id="name" data-required="true" data-error-message="Your Name Is Required"/>

            <textarea className="textarea" placeholder="What's your experience?" />

            <button className="reviewsubmit">Submit</button>
          </div>
          </form> */
