import AOS from "aos";
import "aos/dist/aos.css";
import "../css/home.css";
import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoSearch } from "react-icons/go";
import { SlLocationPin } from "react-icons/sl";
import appointmentimg from "./image/appointmentimg.jpg";
import finddr from "./image/finddr.png";

const Home = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",

      
    });
  }, []);
  
useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
   // Scroll back to top
   const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
    
    
<section className="home-section">
<div className="appointment-container">
  <img className="appointment" src={appointmentimg} alt="Appointment" />
  <div className="home-content-wrapper">
    <div className="appointment-text" data-aos="fade-right">
      <h1>Get 10% discount</h1>
      <p>on all appointments*</p>
      <p>booked and paid online.</p>
    </div>
    <div className="appointment-search-container" data-aos="fade-left">
      <div className="appointment-search">
        <div className="appo-search">
          <GoSearch />
          <input
            type="text"
            placeholder="Search for doctor or speciality"
          />
        </div>
        <div className="appo-search">
          <SlLocationPin />
          <input type="text" placeholder="Search Location" />
        </div>
        <div>
          <button className="book-btn">
            <b>Search</b>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</section>


<section id="specialities">
<div className="specialities-container">
  <div className="specialities-left">
    <h1 className="Specialities">Specialities</h1>
    <div className="line"></div>
    <div className="line2"></div>
    <div className="specialities-grid">
      <div className="speciality-item">
        <img
          src="https://www.maxhealthcare.in/img/cancer%20care%20oncology.svg"
          alt=""
        />
        <span>Cancer Care / Oncology</span>
      </div>
      <div className="speciality-item">
        <img
          src="https://www.maxhealthcare.in/img/The%20Da%20Vinci%20Xi%20Robotic%20Surgery.svg"
          alt=""
        />
        <span>Robotic Surgery</span>
      </div>
      <div className="speciality-item">
        <img src="https://www.maxhealthcare.in/img/cardia.svg" alt="" />
        <span>Cardiac Sciences</span>
      </div>
      <div className="speciality-item">
        <img
          src="https://www.maxhealthcare.in/img/Liver%20Transplant%20and%20bs.svg"
          alt=""
        />
        <span>Liver Transplant</span>
      </div>
      <div className="speciality-item">
        <img
          src="https://www.maxhealthcare.in/img/Orthopaedics.svg"
          alt=""
        />
        <span>Joint Replacement</span>
      </div>
      <div className="speciality-item">
        <img
          src="https://www.maxhealthcare.in/img/Neurosciences.svg"
          alt=""
        />
        <span>Neurosciences</span>
      </div>
      <div className="speciality-item">
        <img
          src="https://www.maxhealthcare.in/img/Gastroenterology.svg"
          alt=""
        />
        <span>Gastroenterlogy</span>
      </div>
      <div className="speciality-item">
        <img
          src="https://www.maxhealthcare.in/img/Thoracic%20Surgery.svg"
          alt=""
        />
        <span>Thoracic</span>
      </div>
    </div>
    <a className="view">View all &gt;</a>
  </div>
  <div className="specialities-right">
    <img className="finddrimg" src={finddr} alt="" />
    <div className="overlay-content">
      <h1>Looking for an Expert</h1>
      <p>
        Medical Healthcare is home to <br />
        some of the eminent doctors.
      </p>
      <button
        onClick={() => navigate("/doctors")}
        className="button-1"
        role="button"
      >
        Find a Doctor &gt;
      </button>
    </div>
  </div>
</div>
</section>

<section className="services-section">
      <div className="container">
        <h1 className="main-heading">Our Services</h1>
        <p className="sub-heading">
          Our medical team in prepared to handle a wide range of medical needs, such as primary
          care, and specialist consultations. We have the knowledge and experience to meet all of
          your healthcare needs.
        </p>

        <div className="services-grid">
          <div className="home-service-item">
            <img
              src="https://www.kynohealth.com/static/media/service1.65a8f59e52f0ff3433ee.png"
              alt="Schedule appointment"
              className="service-image"
            />
            <div className="service-text">
              <h3>Schedule your</h3>
              <h3>appointment via</h3>
              <h3>call/chat</h3>
            </div>
          </div>

          <div className="home-service-item">
            <img
              src="https://www.kynohealth.com/static/media/service2.9226a43b3d5bf9f14752.png"
              alt="Doctors at home"
              className="service-image"
            />
            <div className="service-text">
              <h3>Doctors at</h3>
              <h3>your home</h3>
            </div>
          </div>

          <div className="home-service-item">
            <img
              src="https://www.kynohealth.com/static/media/service3.711c5d4c71aa822c26fd.png"
              alt="Health check ups"
              className="service-image"
            />
            <div className="service-text">
              <h3>Prolonged, detailed</h3>
              <h3>and personalized</h3>
              <h3>health check ups</h3>
            </div>
          </div>

          <div className="home-service-item">
            <img
              src="https://www.kynohealth.com/static/media/service4.e9f544739fe2dbfc58cb.png"
              alt="Diagnostic tests"
              className="service-image"
            />
            <div className="service-text">
              <h3>Prescribed</h3>
              <h3>diagnostic</h3>
              <h3>tests available</h3>
            </div>
          </div>

          <div className="home-service-item">
            <img
              src="https://www.kynohealth.com/static/media/service5.da64ae869f4f955262b4.png"
              alt="Medicines"
              className="service-image"
            />
            <div className="service-text">
              <h3>Medicines</h3>
              <h3>available</h3>
            </div>
          </div>

          <div className="home-service-item">
            <img
              src="https://www.kynohealth.com/static/media/service6.305f70098bfd11b54ced.png"
              alt="Follow-ups"
              className="service-image"
            />
            <div className="service-text">
              <h3>Regular and timely</h3>
              <h3>follow-ups are done</h3>
              <h3>to ensure complete</h3>
              <h3>recovery</h3>
            </div>
          </div>
        </div>
      </div>

      {showTopBtn && (
        <div className="top-to-btm" onClick={scrollToTop}>
          <i class="ri-arrow-up-s-line"></i>
          <span>Top</span>
        </div>
      )}
    </section>
    </>
   
  );
};

export default Home;









