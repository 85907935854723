
import React, { useState, useEffect, } from 'react';
import axios from 'axios';

const Doctorpatientprofile = () => {
  const [doctordata, setdoctordata] = useState([]);

  
  useEffect(() => {
    const id = localStorage.getItem('id');
    doctornameData(id);
  
  },[]);

  const doctornameData = (doctor_name) => {
    return axios.get('http://localhost:8080/doctor/singledoctorlist/'+doctor_name)
      .then((response) => {
        setdoctordata(response.data.message);
      })
  };
  
  return (
    <>
        <div className="patient-card">
          <div className="card-header">
            <div className="doctor-profile1">Get Online <br /> Consultation!</div>
            
          </div>
          <div className="profile-info">

          <p className="consultation-para">
             Do you feel bad... <br /> can't get to the hospital ? <br />
             <p className="consultation-para2">Quick and convenient <br /> consultation with top <br /> specialists without leaving <br /> home.</p>
          
            </p>



            <div className="profile-details">

            </div>
          
          </div>
          
          <div className="appointments">
           
            
            <div className="appointment-item">
              <div className="appointment-indicator"></div>
              <div className="appointment-details">
              <button class="patient-dashboard-button">
                  Book an appointment
                  <svg class="patient-dashboard-button-icon" viewBox="0 0 24 24" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
     
            </div>
          </div>
        </div>
    </>
  )
}

export default Doctorpatientprofile;
