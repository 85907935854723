import React from 'react'
import Header from '../component/header'
import Patient from '../component/patient'

const Patientpage = () => {
  return (
    <>
    <Header/>
    <Patient/>
      
    </>
  )
}

export default Patientpage;
