const Overallperformance = ()=>{
    return(
        <div className="patient-card">
          <div className="card-header"> 
            <div className="overall">Overall Performance</div>
            <span className="performance-indicator">↗ 95%</span>
          </div>
          <div className="progress-circle">
            <svg viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="45" className="progress-background" />
              <circle cx="50" cy="50" r="45" className="progress-bar" />
            </svg>
            <div className="progress-content">
              <span className="progress-score">468</span>
              <span className="progress-label">▲ Perfect</span>
            </div>
          </div>
          <p className="progress-description">
            Your healthier than 95% people
          </p>
          <button className="btn-primary">Check Full Report</button>
        </div>
    )
}

export default Overallperformance;