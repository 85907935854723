import React, { useState, useEffect } from "react";
import axios from 'axios';
import { FaCamera } from "react-icons/fa";


const Doctorprofile = () => {
 
    const [userlist, setuserlist] = useState([]);
  

    
  useEffect(() => {
    const id = localStorage.getItem('id');
    userlistData(id);
  }, []);

  const userlistData = (doctor_name) => {
    return axios.get('http://localhost:8080/doctor/singledoctorlist/'+doctor_name)
      .then((response) => {
        setuserlist(response.data.message);
      })
  };

 

  return (
    <>
    <div className="profile-picture-container">
          <div className="profile-picture">
            
          {userlist && userlist.map((user) => (
            <>
            <img className='doctor-profile-image' src={'image/'+user.doctor_image}/>
            <div className="default-picture">No image</div>
            </>
        ))}
          </div>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="fileInput"
          />
          <label htmlFor="fileInput" className="upload-button">
            <FaCamera />
          </label>

          {userlist && userlist.map((user) => (
              <tr key={user.id}>
               
                <td className="doctor_name" data-label="Name">{user.doctor_name}</td>

              </tr>
            ))} 
      
        </div>
      
    </>
  )
}

export default Doctorprofile;
