import React from 'react'

import Patientdashboard from '../component/patientdashboard'
import Patientdashboardheader from '../component/patientdashboardheader';


const Patientdashboardpage = () => {
  return (
    <>
    {<Patientdashboardheader/>}
    {<Patientdashboard/>}
    
      
    </>
  )
}

export default Patientdashboardpage;
