import { useNavigate } from "react-router-dom";
import { FaFilePrescription } from "react-icons/fa";

const Prescriptioncard = () => {
    const navigate = useNavigate();
  return (
    <>
          <div className="card card-4">
              <div className="card--data"    
               onClick={() => navigate("/prescriptionform")}>
                <div className="card--content">
                  <h5 className="card--title">Prescription</h5>
                  <h1>15</h1>
                </div>
                <div className="pres-icon"><FaFilePrescription /></div>
              </div>
              <div className="card--stats">
                <span>
                  <i className="ri-bar-chart-fill card--icon stat--icon"></i>8%
                </span>
                <span>
                  <i className="ri-arrow-up-s-fill card--icon up--arrow"></i>11
                </span>
                <span>
                  <i className="ri-arrow-down-s-fill card--icon down--arrow"></i>
                  2
                </span>
              </div>
            </div>
    </>
  )
}

export default Prescriptioncard
