
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../css/patientdashboard.css";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { Phone, ChevronLeft, ChevronRight } from 'lucide-react';
import Moment from 'react-moment';
import Overallperformance from './overall-performance';
import Analytics from './analytics';
import Doctorpatientprofile from './doctorpatientprofile';
import Doctorvisithistory from './doctor-visit-history';
import Patientcalendar from './patientcalendar';


const Patientdashboard = () => {

  
  


  

  return (
    <>
      <div className="dashboard">
      <div className="dashboard-grid">
        {/* Overall Performance */}
        {<Overallperformance/>}

        {/* Analytics */}
        {<Analytics/>}

   

        {/* Profile */}
      {<Doctorpatientprofile/>}

      {/* doctor visit history */}
      {<Doctorvisithistory/>}

       
      {/* patientcalendar */}
      {<Patientcalendar/>}
       


      </div>
    </div>
    </>
  );
};

export default Patientdashboard;




        



    