import React from "react";
import "../css/footer.css";
import logomed from "./image/logomed.png";

const Footer = () => {
  return (
    <footer className="hospital-footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={logomed} alt="Medical Health Care Logo" />
            <div>
              <b>Medical</b> Health care
            </div>
          </div>
          <div className="footer-info">
            <div className="contact-info">
              <h3>Contact Us</h3>
              <p>Delhi</p>
              <p>& Noida</p>
              <p>Email: info@medicalhealthcare.com</p>
            </div>
            <div className="social-links">
              <h3>Follow Us</h3>
              <ul>
                <li>
                  <a href="#" aria-label="Instagram">
                    <img
                      src="https://fhlazwebsau01.blob.core.windows.net/drupal-data/2023-03/instagrame.png"
                      alt="Instagram"
                    />
                  </a>
                </li>
                <li>
                  <a href="#" aria-label="Facebook">
                    <img
                      src="https://fhlazwebsau01.blob.core.windows.net/drupal-data/2023-03/facebook.png"
                      alt="Facebook"
                    />
                  </a>
                </li>
                <li>
                  <a href="#" aria-label="Twitter">
                    <img
                      src="https://fhlazwebsau01.blob.core.windows.net/drupal-data/2023-03/twitter.png"
                      alt="Twitter"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;