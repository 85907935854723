import React from 'react'

const Patientsummary = () => {
  return (
    <>
         <div className="graph-chart">
              <h2 className="chart-heading">Patients Summary</h2>
              <div className="donut-chart">
                <div className="slice total"></div>
                <div className="slice new"></div>
                <div className="slice old"></div>
                <div className="chart-center"></div>
              </div>
              <div className="chart-patient-paragrapgh">
                <div className="chart-patient-paragrapgh-item">
                  <span className="color-box new"></span>
                  <span>New Patients</span>
                </div>
                <div className="chart-patient-paragrapgh-item">
                  <span className="color-box old"></span>
                  <span>Old Patients</span>
                </div>
                <div className="chart-patient-paragrapgh-item">
                  <span className="color-box total"></span>
                  <span>Total Patients</span>
                </div>
              </div>
            </div>
    </>
  )
}

export default Patientsummary
