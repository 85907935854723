import React from 'react'
import Drdashheader from '../component/drdashheader';
import Doctorappointmentlist from '../component/doctorappointmentlist';

const Doctorappointmentlistpage = () => {
  return (
    <>
      {<Drdashheader/>}
      {<Doctorappointmentlist/>}

    </>
  )
}

export default Doctorappointmentlistpage;
