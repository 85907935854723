import React from 'react'
import "../css/service.css"

const ServiceItem = ({ iconSrc, text, subText }) => (
  <div className="service-item">
    <img className="service-icon" src={iconSrc} alt="" />
    <div>
      <div className="service-text">{text}</div>
      {subText && <div className="service-text">{subText}</div>}
    </div>
  </div>
);

const Service = () => {
  return (
    <>
      <section className='service-section'>
        <div className="service-container">
          <div className="service-column">
            <ServiceItem 
              iconSrc="https://www.maxhealthcare.in/img/covid-19-icon-for-menu.svg"
              text="Covid-19 Vaccination"
              subText="for children"
            />
            <ServiceItem 
              iconSrc="https://www.maxhealthcare.in/img/emergency.png"
              text="Emergency"
            />
            <ServiceItem 
              iconSrc="https://www.maxhealthcare.in/img/maxLab.svg"
              text="Lab"
            />
            <ServiceItem 
              iconSrc="https://www.maxhealthcare.in/img/InsuranceandTPA.svg"
              text="Insurance and TPA"
            />
            <ServiceItem 
              iconSrc="https://www.maxhealthcare.in/img/PreventiveHealthChecks.svg"
              text="Preventive Health Checks"
            />
            <ServiceItem 
              iconSrc="https://www.maxhealthcare.in/img/FrequentlyAskedQuestions.svg"
              text="Frequently Asked Question"
            />
          </div>

          <div className="serviceline"></div>

          <div className="service-column">
            <h2 className="covidawareness">Covid-19 Prevention</h2>
            <p className="wash">
              Wash hands even if they are visibly clean. To cover your nose and mouth with handkerchief/tissue 
              while sneezing and coughing. To throw used tissues into closed bins immediately after use. To maintain a safe distance 
              from persons during interaction, especially with those having flu-like symptoms.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Service;