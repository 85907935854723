import React from 'react'
import Header from '../component/header';
import Home from '../component/home';
import Footer from '../component/footer';


const Homepage = () => {
  return (
    <>
      {<Header/>}
      {<Home/>}
      {<Footer/>}

    </>
  )
}

export default Homepage;
