import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../css/doctorpatientlist.css";
import Moment from 'react-moment';

const Doctorpatientlist = () => {
  const [userlist, setuserlist] = useState([]);
  const [selecteduser, setSelecteduser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    const id = localStorage.getItem('id');
    userlistData(id);
  }, []);

  const userlistData = (doctor_id) => {
    return axios.get('http://localhost:8080/auth/patientlist/' + doctor_id)
      .then((response) => {
        setuserlist(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching patient list:", error);
      });
  };

  const viewHandler = (id) => {
    return axios.get('http://localhost:8080/auth/singlepatientlist/' + id).then((response) => {
      setSelecteduser(response.data.message[0]);
      setIsModalOpen(true);
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelecteduser(null);
  };

  
useEffect(() => {
  window.addEventListener("scroll", () => {
    if (window.scrollY > 300) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
  });
}, []);
 // Scroll back to top
 const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};
  return (
    <div className="doctor-patient-list">
      <div className="table-container">
        <table className='patient-details'>
          <thead>
            <tr>
              <th>Patient Id</th>
              <th>Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Mobile</th>
              <th>Symptoms</th>
              <th>Treatment</th>
              <th>Date</th>
              <th>Show Details</th>
            </tr>
          </thead>
          <tbody>
            {userlist && userlist.map((user) => (
              <tr key={user.id}>
                <td data-label="Patient Id">{user.id}</td>
                <td data-label="Name">{user.name}</td>
                <td data-label="Age">{user.age}</td>
                <td data-label="Gender">{user.gender}</td>
                <td data-label="Mobile">{user.mobile}</td>
                <td data-label="Symptoms">{user.symptoms}</td>
                <td data-label="Treatment">{user.treatment}</td>
                <td data-label="date"><Moment format='MMMM Do YYYY, h:mm:ss a'>{user.date}</Moment></td>
                <td><button className='viewdetails' onClick={() => viewHandler(user.id)}>View</button></td>
              </tr>
            ))}
          </tbody>
        </table>

        {showTopBtn && (
        <div className="top-to-btm" onClick={scrollToTop}>
          <i class="ri-arrow-up-s-line"></i>
          <span>Top</span>
        </div>
      )}
      </div>

      {isModalOpen && selecteduser && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Patient Details</h2>
            <table className="modal-table">
              <tbody>
                <tr>
                  <th>Id:</th>
                  <td>{selecteduser.id}</td>
                </tr>
                <tr>
                  <th>Name:</th>
                  <td>{selecteduser.name}</td>
                </tr>
                <tr>
                  <th>Age:</th>
                  <td>{selecteduser.age}</td>
                </tr>
                <tr>
                  <th>Gender:</th>
                  <td>{selecteduser.gender}</td>
                </tr>
                <tr>
                  <th>Mobile:</th>
                  <td>{selecteduser.mobile}</td>
                </tr>
                <tr>
                  <th>Symptoms:</th>
                  <td>{selecteduser.symptoms}</td>
                </tr>
                <tr>
                  <th>Treatment:</th>
                  <td>{selecteduser.treatment}</td>
                </tr>
                <tr>
                  <th>Date:</th>
                  <td><Moment format='MMMM Do YYYY, h:mm:ss a'>{selecteduser.date}</Moment></td>
                </tr>
              </tbody>
            </table>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Doctorpatientlist;