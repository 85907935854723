import React from 'react'
import Prescriptionform from '../component/prescriptionform'
import Drdashheader from '../component/drdashheader'


const Prescriptionformpage = () => {
  return (
    <>
    <Drdashheader/>
    <Prescriptionform/>

    </>
  )
}

export default Prescriptionformpage;
