
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Moment from 'react-moment';

const Doctorvisithistory = () => {
      
  const [doctorhistory, setdoctorhistory] = useState([]);

  useEffect(() => {
    const id = localStorage.getItem('id');
    doctorhistoryData(id);
  },[]);


    
  const doctorhistoryData = (id) => {
    return axios.get('http://localhost:8080/auth/singlepatientlist/'+id)
      .then((response) => {
        setdoctorhistory(response.data.message);
      })
    
  };
  return (
    <>
        <div className="patient-card full-width">
            <div className="tab-group">
              <div className="doctor-visit">Doctor Visit History</div>
            </div>
            <div className="lab-reports-container">
              <table className='lab-reports-table'>
                <thead>
                  <tr>
                    <th>Doctor Name</th>
                    <th>Symptoms</th>
                    <th>Treatment</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {doctorhistory && doctorhistory.map((users) => (
                    <tr key={users.id}>
                      <td data-label="Doctor Name">{users.doctor_name}</td>
                      <td data-label="Symptoms">{users.symptoms}</td>
                      <td data-label="Treatment">{users.treatment}</td>
                      <td data-label="Date"><Moment format='MMMM Do YYYY, h:mm:ss a'>{users.date}</Moment></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              
            </div>
          </div>
    </>
  )
}

export default Doctorvisithistory;