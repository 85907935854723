import React from 'react'
import Header from '../component/header';
import Service from '../component/service';

const Servicepage = () => {
  return (
    <>
    {<Header/>}
    {<Service/>}
      
    </>
  )
}

export default Servicepage;
