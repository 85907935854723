import React from 'react'
import Header from '../component/header';
import Doctors from '../component/doctors';


const Doctorspage = () => {
  return (
    <>
    {<Header/>}
    {<Doctors/>}
    

    </>
  )
}

export default Doctorspage;
