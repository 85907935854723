import {BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./pages/homepage";
import Treatmentspage from "./pages/treatmentspage";
import Doctorspage from "./pages/doctorspage";
import Servicepage from "./pages/servicepage";
import Aboutuspage from "./pages/aboutuspage";
import Contactuspage from "./pages/contactuspage";
import Dashboardpage from "./pages/dashboardpage";
import Drregistrationpage from "./pages/drregistrationpage";
import Patientpage from "./pages/patientpage";
import Drdashboardpage from "./pages/drdashboardpage";
import Prescriptionformpage from "./pages/prescriptionformpage";

import Bookanappointmentformpage from "./pages/bookanappointmentformpage";
import Doctorpatientlistpage from "./pages/doctorpatientlistpage";
import Doctorappointmentlistpage from "./pages/doctorappointmentlistpage";
import Patientdashboardpage from "./pages/patientdashboardpage";








function App() {
  return (
   <>
   <BrowserRouter>
   <Routes>
    <Route path="/" element={<Homepage/>}/> 
    {/* <Route path="/treatments" element={<Treatmentspage/>}/>  */}
    <Route path="/doctors" element={<Doctorspage/>}/> 
    <Route path="/bookanappointmentform" element={<Bookanappointmentformpage/>}/>
    <Route path="/service" element={<Servicepage/>}/> 
    <Route path="/aboutus" element={<Aboutuspage/>}/> 
    <Route path="/contactus" element={<Contactuspage/>}/>
    <Route path="/dashboard" element={<Dashboardpage/>}/>
    <Route path="/drregistration" element={<Drregistrationpage/>}/>
    <Route path="/patient" element={<Patientpage/>}/>
    <Route path="/drdashboard" element={<Drdashboardpage/>}/>
    


    <Route path="/prescriptionform" element={<Prescriptionformpage/>}/>
    <Route path="/doctorpatientlist" element={<Doctorpatientlistpage/>}/>
    <Route path="/doctorappointmentlist" element={<Doctorappointmentlistpage/>}/>
    <Route path="/patientdashboard" element={<Patientdashboardpage/>}/>

    


   </Routes>
   </BrowserRouter>


   
   </>
  );
}

export default App;
